@tailwind base;
@tailwind components;
@tailwind utilities;

/* React project default styles이며, 추후에 필요 없어 보이면 지우자 */
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* React dom이 render될 root element. 레이어 우선순에 따른 적적한 z-index를 설정해 주어야 한다 */
#root {
  position: absolute;
  z-index: 100; /* webapp page가 99 */
}

/* customize react-card-swiper package */
.swipe-card__container {
  gap: 0px;
}
.swipe-card__image-container {
  display: none;
}
.swipe-card__content {
  height: 100%;
}

/* customize lovelink element styles */
#root .cafe-card.current {
  height: 100%;
  color: black;
  margin: unset;
  animation: unset;
}

.diagonal-text::before {
   content: '';
  position: absolute;
  top: 50%;
  left: -7.5%;
  right: 0;
  height: 1px;
  background: white;
  transform: rotate(-10deg);
  transform-origin: center;
  width: 115%;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none; 
}

.no-scrollbar::-webkit-scrollbar { display: none; }